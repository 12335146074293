@use 'base';

.done-section {
  &-feed {
    padding: 3.2rem;
    &-title {
      font: var(--done-font-style-normal) normal var(--done-font-weight-normal)
        var(--done-font-size-50) / var(--done-line-spacing-55)
        var(--done-font-family-signal-mono);
      letter-spacing: var(--done-character-spacing--0-5);
      text-align: center;
      text-transform: uppercase;

      padding-bottom: 8rem;
      padding-top: 3.6rem;
    }

    hr {
      border: solid #333834 1px;
    }

    &-article {
      &-anchor {
        &.hidden {
          display: none;
        }
      }
      &-info {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 10rem;

        &:hover {
          cursor: pointer;
        }

        &-title {
          flex-basis: 80%;
          display: none;
        }

        .feed-logo {
          flex-basis: 10%;
          display: flex;
          justify-content: center;

          img {
            max-width: 15rem;
            max-height: 8rem;
            padding: 1rem;
          }
        }

        .arrow-wrapper {
          position: absolute;
          flex-basis: 10%;
          right: 4rem;
          .arrow {
            width: 2.5rem;
            transition: transform 0.7s ease;

            &.open {
              transform: rotate(180deg);
            }
          }
        }
      }

      .show-more {
        overflow: hidden;
        transition: height 0.5s ease;
      }

      &-content {
        display: flex;
        flex-direction: column;
        position: relative;
        padding: 3.4rem;

        text-align: center;
        font: var(--done-font-style-normal) normal
          var(--done-font-weight-normal) var(--done-font-size-16) /
          var(--done-line-spacing-20) var(--done-font-family-pp-neue-montreal);
      }

      .quote {
        &-end {
          text-align: right;
          &-svg {
            height: auto;
          }
        }
        &-start {
          text-align: left;
          &-svg {
            height: auto;
          }
        }
      }

      .read {
        text-align: end;
        padding-top: 1rem;
        padding-bottom: 3rem;
        .read-link {
          font: var(--done-font-style-normal) normal
            var(--done-font-weight-normal) var(--done-font-size-20) /
            var(--done-line-spacing-20) var(--done-font-family-signal-mono);
          letter-spacing: var(--done-character-spacing--0-5);
          text-transform: uppercase;
          text-decoration: underline;
        }
      }
    }

    &-overflow {
      &-trigger {
        margin: 0 auto;
        padding: 3.6rem;

        position: relative;
        width: 7.3rem;
        margin-top: var(--done-line-spacing-30);
        cursor: pointer;

        &::before {
          text-decoration-line: underline;
          font: var(--done-font-style-normal) normal
            var(--done-font-weight-normal) var(--done-font-size-20)
            var(--done-font-family-signal-mono);
          font-size: 3.4rem;

          display: inline-block;
          transform: translate(-50%, -50%);
          white-space: pre;
        }

        @media (pointer: fine) {
          &:hover,
          &-centered:hover {
            color: base.$color-success;
          }
        }
      }

      &-container {
        display: none;
      }
    }

    &-see-more {
      &::before {
        content: 'VOIR PLUS';
      }
    }

    &-see-more-link {
      font: var(--done-font-style-normal) normal var(--done-font-weight-normal)
        var(--done-font-size-20) var(--done-font-family-signal-mono);
      white-space: pre;
      font-size: 3.4rem;
      text-decoration-line: underline;
      display: inline-block;
      transform: translate(-50%, -50%);

      &:hover {
        color: var(--done-color-00ff89);
      }
    }

    &-see-less {
      &::before {
        content: 'VOIR MOINS';
      }
    }
  }
}

@media only screen and (min-width: base.$large-devices) {
  .done-section {
    &-feed {
      &-title {
        padding-top: 1.6rem;
      }
      &-article {
        margin: 0 10rem;

        &-info {
          justify-content: space-between;

          .feed-logo {
            display: block;
          }
          .arrow-wrapper {
            position: initial;
            text-align: right;
            .arrow {
              width: 3.5rem;
            }
          }
          &-title {
            font: var(--done-font-style-normal) normal
              var(--done-font-weight-normal) var(--done-font-size-20) /
              var(--done-line-spacing-28) var(--done-font-family-signal-mono);
            letter-spacing: var(--done-character-spacing--0-5);
            flex-basis: 100%;
            text-align: center;
            text-transform: uppercase;

            &-accordingto {
              font: var(--done-font-style-normal) normal
                var(--done-font-weight-bold) var(--done-font-size-20) /
                var(--done-line-spacing-20) var(--done-font-family-signal-mono);
            }

            display: block;
          }
        }

        &-content {
          text-align: justify;

          padding: 3.5rem;
          margin: 0 5rem;
        }
      }
    }
  }
}

@media only screen and (min-width: base.$x-large-devices) {
  .done-section {
    &-feed {
      &-title {
        padding-top: 3.6rem;
      }
    }
  }
}

@media only screen and (min-width: base.$xx-large-devices) {
  .done-section {
    &-feed {
      &-title {
        padding-top: 6.8rem;
      }

      &-overflow {
        &-trigger {
          padding: 6.8rem;
        }
      }
    }
  }
}

@font-face {
  font-family: PP Neue Montreal;
  src: url("PPNeueMontreal-Regular.5e0546b4.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: PP Neue Montreal;
  src: url("Neue-Montreal-Medium.0edc0724.woff2") format("woff2");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: PP Neue Montreal;
  src: url("Neue-Montreal-Bold.836b8798.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: Signal Mono;
  src: url("Signal Mono-Regular-Web.b0c13c96.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
}

.done-section-feed {
  padding: 3.2rem;
}

.done-section-feed-title {
  font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-50) / var(--done-line-spacing-55) var(--done-font-family-signal-mono);
  letter-spacing: var(--done-character-spacing--0-5);
  text-align: center;
  text-transform: uppercase;
  padding-top: 3.6rem;
  padding-bottom: 8rem;
}

.done-section-feed hr {
  border: 1px solid #333834;
}

.done-section-feed-article-anchor.hidden {
  display: none;
}

.done-section-feed-article-info {
  justify-content: center;
  align-items: center;
  height: 10rem;
  display: flex;
}

.done-section-feed-article-info:hover {
  cursor: pointer;
}

.done-section-feed-article-info-title {
  flex-basis: 80%;
  display: none;
}

.done-section-feed-article-info .feed-logo {
  flex-basis: 10%;
  justify-content: center;
  display: flex;
}

.done-section-feed-article-info .feed-logo img {
  max-width: 15rem;
  max-height: 8rem;
  padding: 1rem;
}

.done-section-feed-article-info .arrow-wrapper {
  flex-basis: 10%;
  position: absolute;
  right: 4rem;
}

.done-section-feed-article-info .arrow-wrapper .arrow {
  width: 2.5rem;
  transition: transform .7s;
}

.done-section-feed-article-info .arrow-wrapper .arrow.open {
  transform: rotate(180deg);
}

.done-section-feed-article .show-more {
  transition: height .5s;
  overflow: hidden;
}

.done-section-feed-article-content {
  text-align: center;
  font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-16) / var(--done-line-spacing-20) var(--done-font-family-pp-neue-montreal);
  flex-direction: column;
  padding: 3.4rem;
  display: flex;
  position: relative;
}

.done-section-feed-article .quote-end {
  text-align: right;
}

.done-section-feed-article .quote-end-svg {
  height: auto;
}

.done-section-feed-article .quote-start {
  text-align: left;
}

.done-section-feed-article .quote-start-svg {
  height: auto;
}

.done-section-feed-article .read {
  text-align: end;
  padding-top: 1rem;
  padding-bottom: 3rem;
}

.done-section-feed-article .read .read-link {
  font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-20) / var(--done-line-spacing-20) var(--done-font-family-signal-mono);
  letter-spacing: var(--done-character-spacing--0-5);
  text-transform: uppercase;
  text-decoration: underline;
}

.done-section-feed-overflow-trigger {
  margin: 0 auto;
  margin-top: var(--done-line-spacing-30);
  cursor: pointer;
  width: 7.3rem;
  padding: 3.6rem;
  position: relative;
}

.done-section-feed-overflow-trigger:before {
  font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-20) var(--done-font-family-signal-mono);
  white-space: pre;
  font-size: 3.4rem;
  text-decoration-line: underline;
  display: inline-block;
  transform: translate(-50%, -50%);
}

@media (pointer: fine) {
  .done-section-feed-overflow-trigger:hover, .done-section-feed-overflow-trigger-centered:hover {
    color: #00ff89;
  }
}

.done-section-feed-overflow-container {
  display: none;
}

.done-section-feed-see-more:before {
  content: "VOIR PLUS";
}

.done-section-feed-see-more-link {
  font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-20) var(--done-font-family-signal-mono);
  white-space: pre;
  font-size: 3.4rem;
  text-decoration-line: underline;
  display: inline-block;
  transform: translate(-50%, -50%);
}

.done-section-feed-see-more-link:hover {
  color: var(--done-color-00ff89);
}

.done-section-feed-see-less:before {
  content: "VOIR MOINS";
}

@media only screen and (width >= 992px) {
  .done-section-feed-title {
    padding-top: 1.6rem;
  }

  .done-section-feed-article {
    margin: 0 10rem;
  }

  .done-section-feed-article-info {
    justify-content: space-between;
  }

  .done-section-feed-article-info .feed-logo {
    display: block;
  }

  .done-section-feed-article-info .arrow-wrapper {
    position: initial;
    text-align: right;
  }

  .done-section-feed-article-info .arrow-wrapper .arrow {
    width: 3.5rem;
  }

  .done-section-feed-article-info-title {
    font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-20) / var(--done-line-spacing-28) var(--done-font-family-signal-mono);
    letter-spacing: var(--done-character-spacing--0-5);
    text-align: center;
    text-transform: uppercase;
    flex-basis: 100%;
    display: block;
  }

  .done-section-feed-article-info-title-accordingto {
    font: var(--done-font-style-normal) normal var(--done-font-weight-bold) var(--done-font-size-20) / var(--done-line-spacing-20) var(--done-font-family-signal-mono);
  }

  .done-section-feed-article-content {
    text-align: justify;
    margin: 0 5rem;
    padding: 3.5rem;
  }
}

@media only screen and (width >= 1200px) {
  .done-section-feed-title {
    padding-top: 3.6rem;
  }
}

@media only screen and (width >= 1480px) {
  .done-section-feed-title {
    padding-top: 6.8rem;
  }

  .done-section-feed-overflow-trigger {
    padding: 6.8rem;
  }
}

/*# sourceMappingURL=index.afb37782.css.map */
